import React from 'react';
import { OverlayModalComponentProps } from '@sharefiledev/sharefile-appshell';
import { entitlementsPiletModals } from '../sdk';
export type Props = Pick<
	OverlayModalComponentProps<typeof entitlementsPiletModals.entitlementsModal>,
	'cancelInteraction'
>;

export const EmptyModal: React.FC<Props> = ({ cancelInteraction }) => {
	return <>{cancelInteraction()}</>;
};

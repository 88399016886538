export enum PiralTestStrings {
	trialUserModal = 'urn:sfmodal:entitlements:trialUserModal',
	trialAdminModal = 'urn:sfmodal:entitlements:trialAdminModal',
	paidUserModal = 'urn:sfmodal:entitlements:paidUserModal',
	paidAdminModal = 'urn:sfmodal:entitlements:paidAdminModal',
	trialUserBanner = 'urn:sfextslot:entitlements:trialUserBanner',
	trialAdminBanner = 'urn:sfextslot:entitlements:trialAdminBanner',
	paidUserBanner = 'urn:sfextslot:entitlements:paidUserBanner',
	paidAdminBanner = 'urn:sfextslot:entitlements:paidAdminBanner',
}

import React from 'react';
import { SolutionPaywallBlockParams } from './types';

const LazyViewClient = React.lazy(() => import('./SolutionPaywall'));
export const AsyncSolutionPaywall: React.FC<SolutionPaywallBlockParams> = param => {
	return (
		<React.Suspense
			fallback={
				<div data-testid="loading-view-client" /> /** Customize this loading if you want */
			}
		>
			<LazyViewClient {...param} />
		</React.Suspense>
	);
};

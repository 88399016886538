import { PiletApi } from '@sharefiledev/sharefile-appshell';
import BlockDevelopment from './BlockDevelopment';

export function addBlockDevelopment(piral: PiletApi) {
	piral.sf.registerLeftNavComponent({
		href: '/entitlements-pilet-block-development',
		title: () => 'Entitlements Block Dev',
		weight: 50,
	});

	piral.registerPage('/entitlements-pilet-block-development', BlockDevelopment);
}

import React from 'react';
import { Spin } from 'antd';
import { LocalizationLoader } from '../translate';
import { PaywallBannerProps } from './PaywallBanner';
import { PaywallModalProps } from './PaywallModal';
import { InstallModalProps } from './SolutionInstallModal';

const LazyPaywallBanner = React.lazy(() => import('./PaywallBanner'));
export const AsyncPaywallBanner: React.FC<PaywallBannerProps> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyPaywallBanner {...props} />
	</React.Suspense>
);

const LazyPaywallModal = React.lazy(() => import('./PaywallModal'));
export const AsyncPaywallModal: React.FC<PaywallModalProps> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyPaywallModal {...props} />
	</React.Suspense>
);

const LazySolutionInstallModal = React.lazy(() => import('./SolutionInstallModal'));
export const AsyncSolutionInstallModal: React.FC<InstallModalProps> = props => (
	<React.Suspense fallback={<Spin />}>
		<LocalizationLoader loadingElement={<Spin />}>
			{() => <LazySolutionInstallModal {...props} />}
		</LocalizationLoader>
	</React.Suspense>
);

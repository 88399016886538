import * as React from 'react';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import InitialManifest from './uiManifest.json';

interface Props {
	piral: PiletApi;
}

const BlockDevelopment = ({ piral: { Extension } }: Props) => {
	const [entitlementDefRID, setEntitlementDefRID] = React.useState(
		'sharefile:entitlementdef-create-project'
	);
	function handleDefRidDropDown(e: React.SyntheticEvent<HTMLSelectElement>) {
		setEntitlementDefRID(e.currentTarget.value);
	}

	function copyToClipboard() {
		navigator.clipboard.writeText(entitlementDefRID);
	}

	return (
		<>
			<h2>Block Development Page</h2>
			Common Entitlements:&nbsp;
			<select name="dropDown" onChange={e => handleDefRidDropDown(e)} id="entitlementRID">
				<option value={'sharefile:entitlementdef-create-doctemplate'}>
					CreateDoctemplate
				</option>
				<option value={'sharefile:entitlementdef-create-project'}>CreateProject</option>
				<option value={'sharefile:entitlementdef-generated-request-lists'}>
					GeneratedRequestLists
				</option>
				<option value={'sharefile:entitlementdef-integration-install'}>
					IntegrationInstall
				</option>
				<option value={'sharefile:entitlementdef-licensed-users'}>LicensedUsers</option>
				<option value={'sharefile:entitlementdef-send-for-signature'}>
					SendForSignature
				</option>
				<option value={'sharefile:entitlementdef-solution-install'}>
					SolutionInstall
				</option>
				<option
					value={'sharefile:entitlementdef-solution-sharefile-us-individual-tax-return'}
				>
					SolutionSharefileUsIndividualTaxReturn
				</option>
				<option value={'sharefile:entitlementdef-storage'}>Storage</option>
				<option value={'sharefile:entitlementdef-template-install'}>
					TemplateInstall
				</option>
			</select>
			<button onClick={() => copyToClipboard()}>Copy</button>
			<Extension
				name="urn:block:view-engine:blockdevelopment"
				params={{ pluginKey: 'entitlements-pilet', initialManifest: InitialManifest }}
			/>
		</>
	);
};

export default BlockDevelopment;
